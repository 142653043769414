import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const thankYou = () => (
	<Layout footerBottom={true}>
		<SEO title="Thank You" />
		<div className="pt-20 text-center">
			<div className="container">
				<h1 className="display-4 font-weight-bold text-primary">Thank You</h1>
				<p className="lead mt-4">
					We have received your message and will get back to you shortly.
				</p>
				<Link to="/" className="mt-4 btn btn-primary">
					Return Home
				</Link>
			</div>
		</div>
	</Layout>
)

export default thankYou
